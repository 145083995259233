












































import { computed, defineComponent } from "@vue/composition-api"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import UnconditionalPositiveRegardStage from "./UnconditionalPositiveRegardStage.vue"

import User from "@shared/User"

import { useViewer } from "@/use"
import useUPRRef from "./useUPRRef"
import useStore from "@/use/useStore"

export default defineComponent({
  name: "UnconditionalPositiveRegard",
  components: {
    ResizableText,
    AppUserCard,
    PlayLayout,
    UnconditionalPositiveRegardStage,
    TextField
  },
  setup() {
    const { id: viewerId, viewer } = useViewer()
    const { store } = useStore()

    const mission = computed(() => store.getters.getCurrentMission)
    const isViewerHostLike = computed(
      () => store.getters["group/isViewerHostLike"]
    )
    const isPresenter = computed(() => User.isPresenter(viewer.value))
    const scribe = computed(() =>
      store.getters["GameUsers/usersOnlineArray"].find(User.isScribe)
    )

    const scribeId = computed(() => scribe.value?.id)

    const userId = computed(() =>
      isViewerHostLike.value ? scribeId.value : viewerId.value
    )

    const { onSubmit } = useUPRRef(userId)

    return {
      isPresenter,
      mission,
      userId,
      scribe,
      onSubmit,
      viewerId,
      isViewerHostLike
    }
  }
})
